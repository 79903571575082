<template>
  <div>
    <PageTitlebar title="Add New Inventory Source">
      <template v-slot:top>
        <Link
          :to="{
            name: 'AdvertiserAccountsOverview',
            query: { to_sources: 1 }
          }"
          label="Back to Sources"
          icon="sym_r_chevron_left"
          class="q-mb-sm"
        />
      </template>
    </PageTitlebar>

    <div>
      <AdvertiserInventorySourceForm
        type="create"
        :submitting="submitting"
        @submit="createInventorySource"
      />
    </div>

    <!-- TODO: The below could be basis for a Tip/TipAlert component -->
    <!-- <div class="flex items-center text-font-secondary" style="margin-top: 200px">
      <q-icon name="sym_r_tips_and_updates" size="xs" class="q-mr-sm" />
      <span style="font-size: 0.85em">
        <span class="text-weight-medium">Want your inventory added to the network now?</span>
        <br>
        Head to your new account's
        <router-link to="/advertiser/accounts/#/inventory-sources">Inventory Sources</router-link>
        and trigger a manual import.
      </span>
    </div> -->
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import Link from "@/components/UI/Link";
import AdvertiserInventorySourceForm from "@/components/Advertiser/AdvertiserInventorySourceForm";
import InventorySourcesMixin from "@/mixins/Advertiser/InventorySourcesMixin";

export default {
  name: "InventorySourceCreator",
  mixins: [InventorySourcesMixin],
  components: {
    AdvertiserInventorySourceForm,
    Link,
    PageTitlebar
  },
  data() {
    return {
      submitting: false
    };
  },
  mounted() {},
  methods: {
    createInventorySource(formData) {
      this.submitting = true;

      this.$store
        .dispatch("inventorySources/POST_REQUEST", {
          endpoint: "create",
          params: {
            advertiser_account_id: formData.advertiserAccountId,
            name: formData.name,
            source_type: formData.sourceType,
            protocol: formData.protocol,
            host: formData.host,
            is_compressed: formData.isCompressed,
            compressed_file_type: formData.compressedFileType,
            compressed_filename: formData.compressedExtractedFilePath,
            file_type: formData.fileType,
            filename: formData.filePath,
            import_time: formData.utcImportTime,
            username: formData.username,
            password: formData.password,
            static_postal_code: formData.defaultPostalCode,
            static_payable_radius: formData.defaultPayableRadius,
            is_facebook_catalog: formData.isFacebookCatalog
          }
        })
        .then(res => {
          if (!res.id) {
            this.triggerActionOutcomeAlert("create", false);
          }
          else {
            let successPromiseChain = [];
            if (formData.sourceType === "manual_upload") {
              successPromiseChain.push(this.performSourceManualUpload(res.id, formData.manualUploadFile));
            }

            Promise.all(successPromiseChain)
              .then(() => {
                this.triggerActionOutcomeAlert("create", true);
                this.submitting = false;

                // Store the source info (name & id) in sessionStorage, and utilize that on success popup that gets shown on Advertiser Accounts Overview
                sessionStorage.setItem(
                  "jf-LastInventorySourceCreatorSuccess",
                  JSON.stringify({
                    id: res.id,
                    name: formData.name
                  })
                );

                this.$router.push({
                  name: "AdvertiserAccountsOverview",
                  query: { advertiser_account_id: formData.advertiserAccountId }
                });
              })
              .catch(() => {
                this.triggerActionOutcomeAlert(
                  "create",
                  false,
                  "Something went wrong while uploading your file. Please ensure the file is valid and try again."
                );
                this.$router.push({
                  name: "InventorySourceEditor",
                  params: {
                    advertiserAccountId: formData.advertiserAccountId,
                    inventorySourceId: res.id
                  }
                });
              })
              .finally(() => {
                this.submitting = false;
              });
          }
        })
        .catch(e => {
          console.log(e);
          if (e.response) {
            this.triggerActionOutcomeAlert(
              "create",
              false,
              e.response.data.errors[0].detail
            );
          } else {
            this.triggerActionOutcomeAlert("create", false);
          }
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
